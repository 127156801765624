import React, { useState, useEffect } from 'react';
import Background from '../../assets/ft-bg.png';
import { FaEnvelope, FaPhone, FaMapMarker, FaHeartbeat } from 'react-icons/fa';
import './Footer.scss';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { path } from '../../constant';
import { sanity, imageUrlBuilder } from '../../sanity';

function Footer(props) {
    const [contacts, setContact] = useState([{ email: '', address: '', phone: '' }]);
    useEffect(() => {
        const cts = ` *[ _type == 'Contacts' ]`;
        sanity.fetch(cts).then(cont => {
            if (cont.length > 0) {
                setContact(cont);
            }
        })
    }, [])
    return (
        <div
            style={{ backgroundImage: `url(${Background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}
            className="text-white text-center pt-5"
        >
            <h4 className='small'>We Are Building Job Creators</h4>
            <h1 className='big-text'>Your donation can change lives.</h1>
            <footer className='d-lg-flex align-items-center justify-content-around bg-black mt-5 py-5 text-start bottom'>
                <div className='w-30 btm'>
                    <h2 className='fw-bolder'>Your Donation can Change their Daily Lifestyle</h2>
                    <Nav.Link href={path.Donate} className="w-50 btn btn-primary text-white py-2">
                        <span className='p-1 bg-white rounded-circle text-primary'>
                            <FaHeartbeat />
                        </span>&nbsp;Donate
                    </Nav.Link>
                </div>
                <div className='btm'>
                    <h6>Links</h6>
                    <ul className='p-0 text-light'>
                        <li className='pb-1'>
                            <a rel="noreferrer" href={path.Home} className='text-light anchor'>Home</a>
                        </li>
                        <li className='py-1'>
                            <a href={path.About} className='text-light anchor'>About us</a>
                        </li>
                        <li className='py-1'>
                            <a rel="noreferrer" href={path.Contact} className='text-light anchor'>Contact us</a>
                        </li>
                    </ul>
                </div>
                <div className='btm'>
                    <h6>
                        Contact
                    </h6>
                    <ul className='text-start p-0 mt-4 text-light'>
                        <li className='text-primary b-bottom'>
                            <FaEnvelope />&nbsp;&nbsp;
                            <a rel="noreferrer" href={`mailto:${contacts[0].email}`} target="_blank" className='text-light'>{contacts[0].email}</a>
                        </li>
                        <li className='text-primary b-bottom'>
                            <FaPhone />&nbsp;&nbsp;
                            <a rel="noreferrer" href={`tel:${contacts[0].phone}`} target="_blank" className='text-light'>{contacts[0].phone}</a>
                        </li>
                        <li className='text-primary'>
                            <FaMapMarker />&nbsp;&nbsp;
                            <a  rel="noreferrer"href={`http://maps.google.com/?q=${contacts[0].address}`} target="_blank" className='text-light'>{contacts[0].address}</a>
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    );
}

export default Footer;