import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Boy from "../../assets/spr.jpeg";
import "./Child.scss";
import { FaPhoneSquare, FaArrowLeft } from 'react-icons/fa';
import { sanity, imageUrlBuilder } from '../../sanity';
import BlockContent from "@sanity/block-content-to-react";
import { useParams } from "react-router-dom";


function ChildDonation(props) {
    const [child, setChild] = useState(null);
    const [accounts, setAccounts] = useState();
    const [contact, setContact] = useState([{ phone: '' }]);

    let { id } = useParams();
    console.log("ID =>", id);

    const serializers = {
        types: {
            code: (props) => (
                <pre data-language={props.node.language}>
                    <code>{props.node.code}</code>
                </pre>
            ),
        },
    }

    useEffect(() => {
        // console.log(decryptData(id));
        const ch = ` *[_type == 'Children'][ _id == '${id}' ][${0}]`;
        const acc = `*[ _type == 'DonationAccount' ]`;
        sanity.fetch(acc).then(data => {
            console.log('acc ->', data);
            if (data.length > 0) {
                setAccounts(data);
            }
        });
        sanity.fetch(ch).then(data => {
            console.log('children ->', data);
            if (data !== null) {
                setChild(data);
            }
        });
        const cts = ` *[ _type == 'Contacts' ]`;
        sanity.fetch(cts).then(cont => {
            if (cont.length > 0) {
                setContact(cont);
            }
        })
    }, [id]);

    return (
        <section className='bg-dark text-light py-5'>
            <div className='container'>
                <h5 className='icon text-primary'><FaArrowLeft /></h5>
                <h1 >Support their dreams</h1>
                <div className='d-lg-flex justify-content-center pt-4 pd'>
                    <img className='child' src={child !== null ? imageUrlBuilder.image(child.profile).url() : Boy} alt="children donations" />
                    <div className='w-70 ms-5 py-3'>
                        <h5>Personal Information</h5>
                        {child !== null ?
                            <p>
                                Interested In Learning : {child.intrest}  <br />
                                County: {child.county}<br />
                                Constituency: {child.constituency} <br />
                                Ward: {child.ward} <br />
                            </p> : null
                        }
                    </div>
                </div>
                <div className='d-lg-flex justify-content-start my-5 story'>
                    <div className=' w-40 align-items-top cd'>
                        <h4>Their Story</h4>
                        <Card className='p-4 text-dark'>
                            {child !== null ? <p>
                                <BlockContent serializers={serializers} blocks={child.story} />
                            </p> : null}
                        </Card>
                    </div>
                    <div className='ms-5 w-40 cd'>
                        <h4>Course Details</h4>
                        <Card className='text-dark p-4'>
                            {
                                child !== null ?
                                    <p className=''>
                                        Total Fee: <strong>Ksh {child.total_fee}</strong> <br /><br />
                                        Course Duration: <strong>{child.course_duration}</strong>
                                    </p>
                                    : null
                            }
                        </Card>
                    </div>
                </div>
                <div>
                    <h4>Donations Account</h4>
                    <div className='d-lg-flex justify-content-start align-items center text-dark my-4'>
                        <Card className='p-3 text-dark w-40'>
                            <Card.Title>Back Account</Card.Title>
                            <Card.Body>
                                {child !== null ? <p>
                                    <BlockContent serializers={serializers} blocks={accounts[0].bank} />
                                </p> : null}
                            </Card.Body>
                        </Card>
                        <Card className='p-3 text-dark w-40 ms-5'>
                            <Card.Title>M-pesa Account</Card.Title>
                            <Card.Body>
                                {child !== null ? <p>
                                    <BlockContent serializers={serializers} blocks={accounts[0].mpesa} />
                                </p> : null}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='my-5 p-4'>
                    <p className='note'>To support via phone or to get assistance with your donation, please contact us at: <br /><br />
                        <FaPhoneSquare /> {" "}
                        {contact.length > 0 ?
                            <a  rel="noreferrer" href={`tel:${contact[0].phone}`}>{contact[0].phone}</a> :
                            null
                        }
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ChildDonation;