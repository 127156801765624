import React, {useEffect, useState} from 'react';
import Logo from "../../assets/logo.png";
import { FaMapMarker, FaEnvelope, FaInstagram, FaFacebookSquare, FaYoutube, FaTwitterSquare } from "react-icons/fa";
import { path } from "../../constant";
import { Navbar, Nav } from "react-bootstrap";
import "./Header.scss";
import { sanity } from '../../sanity';


function Header() {
  const [socials,setSocial] = useState([{facebook: '',twitter:'',youtube:'',instagram:''}]);
  const [contacts,setContact] = useState([{email:'',address:'',phone:''}]);

  let location = window.location;

  useEffect(() => {
    const sld = ` *[ _type == 'SocialLinks' ]`;
    const cts = ` *[ _type == 'Contacts' ]`;
    sanity.fetch(sld).then(socl => {
      if (socl.length > 0) {
          setSocial(socl);
      }
    })
    sanity.fetch(cts).then(cont => {
      console.log('continue',cont);
      if (cont.length > 0) {
          setContact(cont);
      }
    })
  },[location]);
  return (
    <header className="">
      <section className="gradient small-text">
        <div className="container d-flex py-3 flex-row justify-content-between text-white align-items-center">
          <div className="d-lg-flex align-items-center">
            <span className="px-2 fs-6">Temane KINDA Foundation</span>
            {/* <div className="px-2">
              <FaMapMarker />
              <span className="">{contacts[0].address}</span>
            </div> */}
            <div className="px-2">
              <FaEnvelope />
              <a rel="noreferrer" href={`mailto:${''}`} className="px-1">{contacts[0].email}</a>
            </div>
          </div>
          <div className="d-flex justify-content-around align-items-center w-10 socials">
            <a rel="noreferrer" href={socials[0].facebook} target="_blank"><FaFacebookSquare /></a>
            <a rel="noreferrer" href={socials[0].twitter} target="_blank"><FaTwitterSquare /></a>
            <a rel="noreferrer" href={socials[0].instagram} target="_blank"><FaInstagram /></a>
            <a rel="noreferrer" href={socials[0].youtube} target="_blank"><FaYoutube /></a>
          </div>
        </div>
      </section>
      <Navbar collapseOnSelect expand="lg" className='container relative bg-white d-flex justify-content-between sticky-top'>
        <Navbar.Brand><img src={Logo} alt="Temane Kinda Foundation" className='img-fluid logo' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='me-2' />
        <Navbar.Offcanvas id="responsive-navbar-nav" className="text-black text-center d-flex justify-content-center align-items-center fw-bold canvas">
          <Nav activeKey={location.pathname} className="">
            <Nav.Link href={path.Home}>Home</Nav.Link>
            <Nav.Link href={path.About}>About us</Nav.Link>
            <Nav.Link href={path.Contact}>Contact us</Nav.Link>
          </Nav>
          <Nav activeKey={location.pathname} className="donate-btn">
            <Nav.Link href={path.Donate} className="btn btn-primary text-white px-4">Donate</Nav.Link>
          </Nav>
        </Navbar.Offcanvas>
        <Navbar id="responsive-navbar-nav" className="text-black text-center justify-content-end align-items-center fw-bold hide-sm">
          <Nav activeKey={location.pathname} className="">
            <Nav.Link href={path.Home}>Home</Nav.Link>
            <Nav.Link href={path.About}>About us</Nav.Link>
            <Nav.Link href={path.Contact}>Contact us</Nav.Link>
          </Nav>
          <Nav activeKey={location.pathname} className="donate-btn">
            <Nav.Link href={path.Donate} className="btn btn-primary text-white px-4">Donate</Nav.Link>
          </Nav>
        </Navbar>
      </Navbar>
    </header>
  );
}

export default Header;