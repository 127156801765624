export const path = {
    Home:"/",
    About:"/about",
    Contact:"/contact-us",
    Donate:"/donate",
    Child:"/child-donation/:id"
}

export const pages = {
    Home:path.Home,
    About:path.About,
    Contact:path.Contact,
    Donate:path.Donate,
    Child:"/child-donation"
}

export const BASE_URL = "https://trainer-vhwm.onrender.com" // "http://localhost:3000"