import React, {useState, useEffect} from 'react';
import { Card } from 'react-bootstrap';
import "./Donate.scss";
import { FaPhoneSquare } from 'react-icons/fa';
import { sanity } from '../../sanity';
import BlockContent from "@sanity/block-content-to-react";

function Donate(props) {
    const [accounts, setAccounts] = useState([]);
    const [contact, setContact] = useState([{phone:''}]);

    const serializers = {
        types: {
          code: (props) => (
            <pre data-language={props.node.language}>
              <code>{props.node.code}</code>
            </pre>
          ),
        },
      }

    useEffect(() => {
       const acc = `*[ _type == 'DonationAccount' ]`;
       const cont =    `*[ _type == 'Contacts' ]`
       sanity.fetch(acc).then(data => {
        console.log('acc ->', data);
        if (data.length > 0) {
            setAccounts(data);
        }
       });
       sanity.fetch(cont).then(dt => {
        console.log('contacts ->', dt);
        if (dt.length > 0) {
            setContact(dt);
        }
       });
    }, []);
    return (
        <div className='bg-dark text-white py-5'>
            <div className='container my-5'>
                <h1 className='my-5'>Donate to Temane KINDA Foundation</h1>
                <div className='d-lg-flex justify-content-around my-5'>
                    <Card className='text-black p-3 w-40 card'>
                        <Card.Title>Bank Account Donations</Card.Title>
                        <Card.Body>
                            {
                                accounts.length >0 ?
                                <BlockContent serializers={serializers} blocks={accounts[0].bank} />
                                :
                                <p></p>
                            }
                        </Card.Body>
                    </Card>
                    <Card className='text-black p-3 w-40 card'>
                        <Card.Title>M-pesa Account Donations</Card.Title>
                        <Card.Body>
                            {
                                accounts.length >0?
                                <BlockContent serializers={serializers} blocks={accounts[0].mpesa} />
                                :
                                <p></p>
                            }
                        </Card.Body>
                    </Card>
                </div>
                <div className='my-5 p-4'>
                    <p className='note'>To Give By Phone or to get assistance with your donation, please contact us at: <br /><br />
                        <FaPhoneSquare/> {" "}
                         <a rel="noreferrer" href={`tel:${contact[0].phone}`}>{contact[0].phone}</a></p>
                </div>
            </div>
        </div>
    );
}

export default Donate;