
import { createClient } from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';

export const sanity = createClient({
  projectId: '8au610te',
  dataset: 'production',
  apiVersion: '2021-08-31',
  // this option enables faster responses
  // but can return stale data at times.
  // recommended for client-side queries
  useCdn: true,
  token:
    'skYCfY9nv4cOvAwyhVvbTYlSjjJrMk2kUudZKkcwqicgKRZwCp2RWWl07oxqnIbhY1JRsDLlI2SpJOB9ZZHogCcM7RU0bByLqNoOQ8rqx5CBq4xUMN89Lcr2H3NjrKeZlKiPI1THpEDKPwDDunJHhFRIKJapbxRvhUVha2pEGnyeI7UGomov',
});

export const imageUrlBuilder = ImageUrlBuilder(sanity);
