import React from 'react';
import Listing from "../../components/Listing";
import Slider from "../../components/Slider";
import Support from "../../components/Support";

function Home(props) {
    return (
        <div>
            <Slider />
            <Listing />
            <Support />
        </div>
    );
}

export default Home;