import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ChildDonation from "../components/ChildDonation";
import Donate from "../components/Donate";
import { path } from "../constant";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";



export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={path.Home} element={<Home />} />
        <Route path={path.About} element={<About />} />
        <Route path={path.Contact} element={<Contact />} />
        <Route path={path.Donate} element={<Donate />} />
        <Route path={path.Child} element={<ChildDonation />} />
        <Route path="*" element={<Navigate to={path.Home} replace />} />
      </Routes>
    </BrowserRouter>
  );
}