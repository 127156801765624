import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaChevronCircleLeft, FaChevronCircleRight, FaHeartbeat } from 'react-icons/fa';
import Boy from "../../assets/spr.jpeg"
import { pages } from '../../constant';
import "./Listing.scss";
import { sanity, imageUrlBuilder } from '../../sanity';


function Listing(props) {
    const [children, setChildren] = useState([]);

    const donate = (id) => {
        // let ecryptedID = encryptData(id);
        window.location.href = `${pages.Child}/${id}`;
    }
    useEffect(() => {
        const childn = ` *[ _type == 'Children' ]`;
        sanity.fetch(childn).then(ch => {
            console.log('cont', ch);
            if (ch.length > 0) {
                setChildren(ch);
            }
        })
    }, []);
    return (
        <section className='bg-tertiary'>
            <div className='container py-5'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h1 className='text-primary'>Support Their Dreams</h1>
                    <div className='w-7 d-flex justify-content-around fs-1 text-white cursor nav-arrow'>
                        <FaChevronCircleLeft />
                        <FaChevronCircleRight />
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-start align-items-center my-4'>
                    {children.map((child, i) => (
                        <Card key={i} className="mx-2 my-2 card cursor w-30">
                            <Card.Img variant="top" src={child !== null ? imageUrlBuilder.image(child.profile).url() : Boy} />
                            <Card.Body className='card-body'>
                                <Card.Title>{child.name}</Card.Title>
                                <Card.Text>
                                    Interested In Learning: <span className='fw-bold'>{child.intrest}</span><br />
                                    County: <span className='fw-bold'>{child.county}</span> <br />
                                    Constituency: <span className='fw-bold'>{child.constituency}</span> <br />
                                    Ward:  <span className='fw-bold'>{child.ward}</span><br />
                                </Card.Text>
                                <Button variant="primary" className='w-100' onClick={() => donate(child._id)}>
                                    <span className='p-1 bg-white rounded-circle text-primary'>
                                        <FaHeartbeat />
                                    </span>
                                    <span className='px-3'>Support</span>
                                </Button>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
                <div className='d-flex justify-content-center'>
                    {/* <Button variant='outline-primary' className='w-10'>View All</Button> */}
                </div>
            </div>
        </section>
    );
}

export default Listing;