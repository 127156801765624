import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Router from "./route";

function App() {
  
  return (
    <React.Fragment>
      <Header/>
      <Router/>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
