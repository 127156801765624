import React from 'react';
import SP from "../../assets/support.png";
import PR from "../../assets/prayer.png";
import VL from "../../assets/volunteer.png";
import DN from "../../assets/donations.png";
import './Support.scss';

function Support(props) {
    return (
        <div className='text-center py-5 container my-5 support'>
            <h1 className='fw-bold mb-5 title'>We believe that when enough people have confidence and entrepreneurship skills an economy can thrive and enough jobs can be created to alleviate poverty.</h1>
            <div className='d-lg-flex py-5 my-5 sp-box'>
                <div className='p-4 bx'>
                    <img className='sp-icons ptm' src={SP} alt="" />
                    <h5 className='mt-4 fw-bold'>Support</h5>
                    <p>Help a needy student achieve His/Her dreams and become a contributor to our Job Creation mission.</p>
                </div>
                <div className='p-4 bx'>
                    <img className='sp-icons ptm' src={VL} alt="" />
                    <h5 className='mt-4 fw-bold'>Trainings</h5>
                    <p>We pair learners with experienced business people to guide them through skills mastery and business startup.</p>
                </div>
                <div className='p-4 bx'>
                    <img className='sp-icons ptm' src={DN} alt="" />
                    <h5 className='mt-4 fw-bold'>Donations</h5>
                    <p>It is a blessing to have you help us train as many job creators as possible. </p>
                </div>

            </div>
        </div>
    );
}

export default Support;