import React, { useState, useEffect } from 'react';
import Slider from '../../components/Slider';
import Pattern from "../../assets/pattern.png";
import Mother from "../../assets/mother.png";
import "./About.scss";
import { sanity, imageUrlBuilder } from '../../sanity';
import BlockContent from "@sanity/block-content-to-react";


function About(props) {
    const [story, setStory] = useState([]);

    const serializers = {
        types: {
            code: (props) => (
                <pre data-language={props.node.language}>
                    <code>{props.node.code}</code>
                </pre>
            ),
        },
    }
    useEffect(() => {
        const about = ` *[ _type == 'about' ]`;
        sanity.fetch(about).then(ab => {
            console.log('about ->', ab);
            if (ab.length > 0) {
                setStory(ab);
            }
        })
    }, []);
    return (
        <section>
            <Slider />
            <div className=''>
                <div className='container py-5'>
                    <h1 className='text-purple'>About Us</h1>
                    {
                        story.length > 0
                            ?
                            <BlockContent serializers={serializers} blocks={story[0].our_story} />
                            :
                            <div>Loading ...</div>
                    }
                </div>
                {
                    story.length > 0
                        ?
                        <div className='py-5 h-25 text-white bg-fx' style={{ backgroundImage: `url(${Pattern})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            <div className='container d-lg-flex align-items-center justify-content-between py-5'>
                                <img src={imageUrlBuilder.image(story[0].goal_image_cover).url()} alt='children' className='image' />
                                <div className='bty'>
                                    <h1>{story[0].goal}</h1>
                                    <BlockContent serializers={serializers} blocks={story[0].description} />
                                </div>
                            </div>
                        </div>
                        :
                        <div></div>
                }
            </div>
        </section>
    );
}

export default About;