import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Bg from "../../assets/contact.jpeg";
import './Contact.scss';

const API = "https://mailersendgrid.glitch.me/api";

function Contact(props) {
    const [loader, setLoader] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let form = {
            subject: "Mesage from Temane KINDA Foundation Website",
            html: `<h1>Contact details:</h1><h5>Name: ${e.target[0].value}${" "}${e.target[1].value}</h5><h5>Email: ${e.target[2].value}</h5><p>Message: ${e.target[3].value}</p>`,
        }
        console.log(form);
        fetch(API, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setLoader(false);
            })
    }

    return (
        <>
            {
                loader === false ?
                    <div className='py-5'>
                        <div style={{ backgroundImage: `url(${Bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionc: 'top' }} className="text-white py-5">
                            <h1 className='container my-5 large-text'>Get in touch</h1>
                        </div>
                        <div className='py-5 container'>
                            <h5 className='text-primary'>Contact Temane KINDA Foundation</h5>
                            <br />
                            <Form className='form' onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="fname" placeholder="Enter First Name" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="lname" placeholder="Enter Last Name" required />

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Enter email" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        placeholder="Enter your message here"
                                        style={{ height: '150px' }}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className='w-25 mt-3'>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                    :
                    <div className='full'>
                        <div className="loader"></div>
                        <h6 className='py-5'>Sending ...</h6>
                    </div>
            }
        </>
    );
}

export default Contact;