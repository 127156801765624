import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Fade } from 'react-slideshow-image';
import { path } from '../../constant';
import "./slider.scss";
import { sanity, imageUrlBuilder } from '../../sanity';

function Slider(props) {
    const [slides, setSlides] = useState([]);
    let location = window.location.pathname;
    console.log(location);
    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
    }
    const slideImages = [
        {
            url: 'https://images.squarespace-cdn.com/content/v1/55cb3e0ce4b00d6bdfd8af41/1597322145568-BUM9AUC4DZFWO2BWAG8S/image-asset.jpeg?format=2500w',
            caption: 'Lorem ipsum dolor sit amet consectetur. Aenean tortor sem ipsum placerat justo ut quam aenean duis.'
        },
        {
            url: 'https://www.excelep.com/wp-content/uploads/2015/08/cover4.jpg',
            caption: 'Slide 2'
        }
    ];

    useEffect(() => {
        const sld = ` *[ _type == 'imageSlider' ]`;
        sanity.fetch(sld).then(img => {
            console.log('slides', img);
            if (img.length > 0) {
                setSlides(img);
            }
        })
    }, [location])
    return (
        <div className="">
            <Fade scale={0.4} arrows={false} autoplay={true} duration={2000} infinite={true}>
                {slides.map((slideImage, index) => (
                    <div key={index} >
                        <div style={{ ...divStyle, 'backgroundImage': `url(${imageUrlBuilder.image(slideImage.slide).url()})` }} className="slide-container">
                            <div className='text-white caption container fit-h'>
                                <div className='overlay'></div>
                                <h1 className='zindex-modal'>{slideImage.title} </h1>
                                {location !== path.About ?
                                    <Button variant='primary' className='px-6 py-2 my-4 text-uppercase top'>
                                        <NavLink href={path.About} className="px-4">Learn More</NavLink>
                                    </Button>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    );
}

export default Slider;